<style>
.multiselect__single {
    white-space: nowrap;
    overflow: hidden;
}

.p-multiSelect {
    width: 760px !important
}

.selectSociety {
    width: 760px !important;
    /* max-width: fit-content; */
}

.add-owner {
    float: right;
}
</style>

<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="sale.id === null">Register Sale</h5>
                <h5 v-if="sale.id !== null">Edit Sale</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row v-if="client.type === 0">
                            <b-col cols="12">
                                <br>
                                <b-form-checkbox class="custom-control-success" v-model="sale.isCentral">
                                    <small>Is Central Market?</small>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>


                        <b-row class="mt-2">
                            <b-col cols="3">
                                <b-form-group label-for="season_id" label="Season">
                                    <validation-provider #default="{ errors }" name="Season" rules="">
                                        <b-form-select id="season_id" name="season_id" size="md"
                                            v-model="sale.season_id" disabled>
                                            <b-form-select-option v-for="season in seasons" :key="season.id"
                                                :value="season.id">
                                                {{ season.startYear }}/{{ season.endYear }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{
                                                serverErrors.season_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="5" v-if="client.type !== 0">
                                <b-form-group label-for="buyer_id" label="Buyer">
                                    <validation-provider #default="{ errors }" name="Buyer" rules="">
                                        <b-form-select
                                            id="buyer_id"
                                            name="buyer_id"
                                            size="md"
                                            v-model="sale.buyer_id"
                                            :disabled="buyers.length === 1"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option
                                                v-for="(buyer, index) in buyers"
                                                :key="index"
                                                :value="buyer.id"
                                                :selected="index === 0"
                                            >
                                                {{ buyer.abbreviation }}: {{ buyer.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.buyer_id">{{
                serverErrors.buyer_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col v-for="(selected, index) in formSelections" :key="selected.level" cols="4"
                                v-if="locId.id < 1 && !sale.isCentral">
                                <b-form-group :label-for="selected.level" :label="selected.label">
                                    <validation-provider #default="{ errors }" :name="selected.label" rules="">
                                        <b-form-select :id="selected.level" :name="selected.level" size="md"
                                            :state="errors.length > 0 ? false : null" v-model="selected.model"
                                            @change="changeFormSelection(selected.model, index)">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="location in selected.locations"
                                                :key="location.id" :value="location">
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="10" v-if="locId.id < 1 && sale.isCentral">
                                <b-form-group labe-for="location_id" label="Region">
                                    <validation-provider #default="{ errors }" name="Region" rules="">
                                        <b-form-select id="location_id" name="location_id" size="md"
                                            v-model="sale.location_id" @change="getAreaMarket()">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="location in regions" :key="location.id"
                                                :value="location.id">
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                             serverErrors.location_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="10" v-if="locId.id > 0 && !sale.isCentral">
                                <b-form-group labe-for="location_id" label="Area">
                                    <validation-provider #default="{ errors }" name="Area" rules="">
                                        <b-form-select id="location_id" name="location_id" size="md"
                                            v-model="sale.location_id" @change="getAreaMarket()">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="location in locId.children" :key="location.id"
                                                :value="location.id">
                                                {{ location.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>


                        <b-row>
                            <!-- <b-col cols="6">
                                <b-form-group labe-for="code" label="Market CODE">
                                    <validation-provider #default="{ errors }" name="Market CODE" rules="">
                                        <b-form-select id="code" name="code" size="md" v-model="sale.market_id"
                                            @change="displaySaleNumber()">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="market in markets" :key="market.id"
                                                :value="market.id">
                                                {{ market.code.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                            serverErrors.market_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col> -->
                            <b-col cols="12">
                                <b-form-group labe-for="name" label="Market Center">
                                    <validation-provider #default="{ errors }" name="Market Center" rules="">
                                        <b-form-select id="name" name="name" size="md" v-model="sale.market_id"
                                            @change="displaySaleNumber($event)">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="market in markets" :key="market.id"
                                                :value="market.id">
                                                {{ market.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                serverErrors.market_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>


                        </b-row>
                        <b-row v-if="client.type === 0">
                            <b-col cols="12">
                                <b-form-group labe-for="Transporter" label="Transporter">
                                    <b-form-select size="md" v-model="sale.transporter_id">
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="transporter in transporters" :key="transporter.id"
                                            :value="transporter.id">
                                            {{ transporter.name.toUpperCase() }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- <b-col cols="4">
                                <b-form-group labe-for="tmpNumber" label="TMP Number">
                                    <validation-provider #default="{ errors }" name="TMP Number" rules="">
                                        <b-form-input id="tmpNumber" name="tmpNumber" size="md" v-model="sale.tmpNumber"
                                            type="number" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.tmpNumber">{{
                                            serverErrors.tmpNumber[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col> -->


                            <b-col cols="6">
                                <b-form-group labe-for="number" label="Sale #" :disabled="client.type == 0">
                                    <validation-provider #default="{ errors }" name="Sale #" rules="">
                                        <b-form-input id="number" name="number" size="md" v-model="sale.number"
                                            type="number" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.number">{{
                serverErrors.number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>


                            <b-col cols="6" v-if="client.type < 2">
                                <b-form-group labe-for="saleDate" label="Sale Date">
                                    <validation-provider #default="{ errors }" name="Sale Date" rules="">
                                        <b-form-datepicker id="saleDate" :min="minDate" name="saleDate"
                                            v-model="sale.saleDate" :state="errors.length > 0 ? false : null" size="md"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.saleDate">{{
                serverErrors.saleDate[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>


                            <b-col cols="12" v-if="client.type === 0">

                                <b-form-group labe-for="Societies" label="Societies">
                                    <MultiSelect v-model="selectedSocieties" :options="societies" filter
                                        optionLabel="name" @input="onChange" display="chip" optionValue="id"
                                        placeholder="Select Societies" :selectedItemsLabel="3"
                                        class="w-full md:w-50rem selectSociety" />
                                </b-form-group>


                            </b-col>
                        </b-row>

                        <b-row v-if="sale.societies.length > 0">
                            <!-- <b-col>
                                <b-button size="sm" class="add-owner mb-1" variant="outline-primary">Add TMP</b-button>
                            </b-col> -->

                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>PS</th>
                                        <th>TMP NUMBER</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="element, index in  sale.societies" :key="index">
                                        <td scope="row">
                                            <div>

                                                <b-form-input id="share" name="id" v-model="element.name" size="md"
                                                    readonly />

                                            </div>
                                        </td>

                                        <td>
                                            <div>

                                                <b-form-input id="share" name="tmpNumber" v-model="element.tmpNumber"
                                                    size="md" />

                                            </div>

                                        </td>
                                        <!-- {{ element }} -->
                                    </tr>
                                    <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <b-row v-if="client.type === 0">
                            <b-col cols="12">
                                <b-form-group labe-for="user_id" label="FT">
                                    <validation-provider #default="{ errors }" name="ft" rules="">
                                        <b-form-select id="user_id" name="user_id" size="md" v-model="sale.user_id">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="ft in fts" :key="ft.id" :value="ft.id">
                                                {{ ft.person.firstName }} {{ ft.person.lastName }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.user_id">{{
                serverErrors.user_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- <pre>
                    {{ sale }}
                </pre> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->
        
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myCancelModal" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submitCancel">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Cancel Sale</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCancel">
                        <validation-provider #default="{ errors }" name="Season" rules="">
                            <b-form-group label="Cancel sale reason" label-for="textarea-formatter" class="mb-0">
                                <b-form-textarea id="textarea-formatter" v-model="cancelSale.reason"
                                    placeholder="Enter your text"></b-form-textarea>
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-danger" v-if="serverErrors && serverErrors.reason">{{
                serverErrors.reason[0] }}</small>
                        </validation-provider>

                    </b-form>
                </validation-observer>

            </template>

        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="2">
                        <label>Season</label>
                        <b-form-select size="md" v-model="sale.season_id" @change="changeSeason()" disabled>
                            <b-form-select-option v-for="season in seasons" :key="season.id" :value="season.id"
                                :selected="!season.isRunning">
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="6" md="3" v-if="client.type !== 0">
                        <label>Buyer</label>
                        <b-form-select size="md" v-model="sale.buyer_id" @change="changeBuyer()"
                            :disabled="buyers.length === 1">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="buyer in buyers" :key="buyer.id" :value="buyer.id">
                                {{ buyer.abbreviation }}: {{ buyer.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="12" v-if="locId.id < 1" md="2" class="mb-md-0 mb-2" v-for="(toSelect, index) in selections"
                        :key="toSelect.level">
                        <label>{{ toSelect.label }}</label>
                        <b-form-select size="md" v-model="toSelect.model" @change="changeFilters(toSelect.model, index)">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="location in toSelect.locations" :key="location.id"
                                :value="location">
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>



                    <b-col cols="4" v-if="locId.id > 0">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="sale.location_id"
                                    @change="getAreaMarket()">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="4" >
                        <b-form-group labe-for="market_id" label="Market">
                        <b-form-select size="md" v-model="sale.market_id" @change="changeMarket()">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                {{ market.code.toUpperCase() }}: {{ market.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Register Sale</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>


                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(market)="data">
                    <span v-if="data.item.market.code !== null">{{ data.item.market.code.toUpperCase() }}: </span>{{ data.item.market.name.toUpperCase() }}
                </template>
                <template #cell(saleDate)="data">
                    <span v-if="data.item.effectiveDate">{{ data.item.effectiveDate | moment('DD/MM/YYYY') }}</span>
                    <span v-else>{{ data.item.saleDate | moment('DD/MM/YYYY') }}</span>
                </template>
                <template #cell(isClosed)="data">
                    <b-badge pill :variant="`light-${resolveStatusVariant(data.item.isClosed)}`"
                        class="text-capitalize">
                        <span v-if="!!data.item.isClosed">Closed</span>
                        <span v-else>Opened</span>
                    </b-badge>
                </template>
                <template #cell(closeDate)="data">
                    {{ data.item.closeDate | moment('DD/MM/YYYY') }}
                </template>
                <template #cell(ticketsCount)="data">
                    <span v-if="data.item.ticketsCount > 0">{{ data.item.captured }}/{{ data.item.ticketsCount }}</span>
                    <span v-else>{{ data.item.ticketsCount }}</span>
                </template>
                <template #cell(buyer)="data">
                    <span v-if="data.item.buyer.abbreviation">{{ data.item.buyer.abbreviation.toUpperCase() }}: </span>{{ data.item.buyer.name.toUpperCase() }}
                </template>

                <template #cell(transporter)="data">
                    {{ data.item.transporter ? data.item.transporter.name.toUpperCase() : '' }}
                </template>
                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeCancelForm(data.item)">
                            <feather-icon icon="XSquareIcon" />
                            <span class="align-middle ml-50">Cancel Sale</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner, BBadge, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import salesStoreModule from '@/views/cromis/sales/sale/salesStoreModule'
import useSalesList from '@/views/cromis/sales/sale/useSalesList'
import MultiSelect from 'primevue/multiselect';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
        ValidationObserver, ValidationProvider, MultiSelect, BFormTextarea
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myCancelModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const seasons = ref([])
        const buyers = ref([])
        const markets = ref([])
        const fts = ref([])
        const regions = ref([])
        const societies = ref([])
        const societiesForTmp = ref([])
        const saleData = ref()
        const selectedSocieties = ref([]);
        const transporters = ref([])
        const areaSocieties = ref([]);
        const areaSocietiesToEdit = ref([]);

        const client = ref(JSON.parse(localStorage.getItem('userData')).client)
        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)

        const now = new Date()
        const today = ref(new Date(now.getFullYear(), now.getMonth(), now.getDate()))
        const minDate =ref(new Date(today.value))
        const sale = ref({
            id: null,
            location_id: null,
            season_id: null,
            buyer_id: null,
            market_id: null,
            number: null,
            saleDate: null,
            isCentral: false,
            effectiveDate: null,
            isClosed: false,
            closeDate: null,
            societies_id: null,
            tmpNumber: null,
            transporter_id: null,
            user_id: null,
            societies: [
                {
                    tmpNumber: null,
                    id: null,
                    name: null
                }
            ]
        })


        const cancelSale = ref({
            id: null,
            sale_id: null,
            reason: null,
        })
        const amcosList = ref([])

        const CROMIS_STORE_MODULE_NAME = 'cromis-sale'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, salesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })


        onMounted(async () => {
            await store.dispatch('cromis-sale/transporters')
                .then(response => {
                    transporters.value = response.data.transporters
                }).catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-sale/levels')
                .then(response => {
                    levels.value = response.data.levels[0]

                    selections.value.push({
                        level: levels.value.name.toLowerCase(),
                        model: null,
                        label: levels.value.name,
                        child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                        locations: [],
                    })


                    formSelections.value.push({
                        level: levels.value.name.toLowerCase(),
                        model: null,
                        label: levels.value.name,
                        child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                        locations: [],
                    })

                    let list = levels.value.children


                    while (list.length > 0) {
                        children.value.push(list[0])
                        if (list[0].children > 0) {
                            selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                            formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                        }
                        else {
                            selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                            formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                        }

                        list = list[0].children
                    }
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-sale/locations')
                .then(response => {
                    regions.value = response.data.locations
                    populateSelections(response.data.locations)

                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-sale/seasons', { isRunning: true })
                .then(response => {
                    seasons.value = response.data.seasons

                    if (seasons.value.length > 0) {
                        sale.value.season_id = seasons.value[0].id
                        changeSeason(sale.value.season_id)

                      
                        seasons.value.forEach(e => {
                            if (e.isRunning) {
                                minDate.value = new Date(e.startDate)
                            }
                        })
                    }
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-sale/buyers')
                .then(response => {
                    buyers.value = response.data.buyers
                    if (buyers.value.length === 1) {
                        sale.value.buyer_id = buyers.value[0].id
                    }
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-sale/markets')
                .then(response => {
                    markets.value = response.data.markets
                })
                .catch(error => {
                    console.log('got error', error)
                })



            await store.dispatch('cromis-sale/societies', { location_id: locId.value.id })
                .then(response => {
                    societies.value = response.data.societies
                    societies.value.forEach(el => {
                        areaSocieties.value.push({ "id": el.id, "name": el.name })
                    });
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-sale/markets', { location_id: locId.value.id })
                .then(response => {
                    markets.value = response.data.markets
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })


        const geFtUsers = async (societies) => {
            let payload = {
                'societies': societies
            }
            await store.dispatch('cromis-sale/agronomists', payload)
                .then(response => {
                    fts.value = response.data.agronomists
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }


        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        const displaySaleNumber = (event) => {
            saleData.value = event
            sale.value.number = event.saleNumber
            // sale.value.market_id = event.id
            // sale.value.transporter_id = event.transporter ? event.transporter.id : null
            if (client.value.type == 0) {
                for (let x = 0; x < markets.value.length; x++) {
                    if (markets.value[x].id == sale.value.market_id) {
                        sale.value.number = markets.value[x].saleNumber
                        sale.value.transporter_id = markets.value[x].transporter.id
                    }
                }
            }
        }

        const onChange = async () => {
            sale.value.societies = []
            let socItes = [];

            societies.value.forEach(ele => {
                for (let index = 0; index < selectedSocieties.value.length; index++) {
                   if(ele.id == selectedSocieties.value[index]){
                    // amcosList.value.push(ele)
                    sale.value.societies.push({ tmpNumber: '', id: ele.id, name: ele.name })
                    socItes.push(ele.id)
                   }
                    
                }
            });
    
            // amcosList.value = selectedSocieties.value
            //     .filter(amcos => societies.value.includes(amcos.id))
            //     .map(amcos => amcos.id);

            let payload = {
                'societies': socItes
            }

            await store.dispatch('cromis-sale/agronomists', payload)
                .then(response => {
                    fts.value = response.data.agronomists
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeFilters = async (location, index) => {
            market_id.value = null
            sale.value.market_id = null

            if (!location) {
                for (let i = index + 1; i < selections.value.length; i++) {
                    selections.value[i].locations.splice(0)
                    selections.value[i].model = null
                }

                if (index > 0) {
                    locationFilter.value = selections.value[index - 1].model.id
                }
                else {
                    locationFilter.value = null
                }
            }
            else {
                if (selections.value[index + 1]) {
                    selections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    selections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    selections.value[index + 1].model = null
                }

                locationFilter.value = selections.value[index].model.id
            }


        }

        const changeSeason = async () => {
            season_id.value = sale.value.season_id
        }

        const changeBuyer = async () => {
            buyer_id.value = sale.value.buyer_id
        }

        const changeMarket = async () => {
            market_id.value = sale.value.market_id
        }

        const getMarket = async (loc_id) => {
            await store.dispatch('cromis-sale/societies', { location_id: location.id })
                .then(response => {
                    societies.value = response.data.societies
                    societies.value.forEach(el => {
                        areaSocieties.value.push({ "id": el.id, "name": el.name })
                    });
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getAreaMarket = async () => {
            await store.dispatch('cromis-sale/societies', { location_id: sale.value.location_id })
                .then(response => {
                    societies.value = response.data.societies
                    societies.value.forEach(el => {
                        areaSocieties.value.push({ "id": el.id, "name": el.name })
                    });
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-sale/markets', { location_id: sale.value.location_id })
                .then(response => {
                    markets.value = response.data.markets
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeFormSelection = async (location, index) => {
            market_id.value = null

            sale.value.market_id = null

            if (!location) {
                for (let i = index + 1; i < formSelections.value.length; i++) {
                    formSelections.value[i].locations.splice(0)
                    formSelections.value[i].model = null
                }

                sale.value.location_id = null
            }
            else {
                sale.value.location_id = null

                if (formSelections.value[index + 1]) {
                    formSelections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    formSelections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    formSelections.value[index + 1].model = null
                }

                if (index === formSelections.value.length - 1) {
                    sale.value.location_id = location.id
                }
            }

            await store.dispatch('cromis-sale/societies', { location_id: location.id })
                .then(response => {
                    societies.value = response.data.societies
                    societies.value.forEach(el => {
                        areaSocieties.value.push({ "id": el.id, "name": el.name })
                    });
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-sale/markets', { location_id: location.id })
                .then(response => {
                    markets.value = response.data.markets
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
            season_id,
            buyer_id,
            market_id,

            // Resolvers
            resolveStatusVariant,
        } = useSalesList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            let location = item.market.address.location
            let selectedLocations = []
            while (location) {
                selectedLocations.push({ level: location.level.name.toLowerCase(), id: location.id })
                location = location.parent
            }

            for (let i = 0; i < formSelections.value.length; i++) {
                for (let n = 0; n < selectedLocations.length; n++) {
                    if (formSelections.value[i].level === selectedLocations[n].level) {
                        for (let x = 0; x < formSelections.value[i].locations.length; x++) {
                            if (formSelections.value[i].locations[x].id === selectedLocations[n].id) {
                                formSelections.value[i].model = formSelections.value[i].locations[x]

                                if ((i + 1) < formSelections.value.length) {
                                    formSelections.value[i + 1].locations = formSelections.value[i].locations[x].children
                                }
                            }
                        }
                    }
                }
            }

            selectedSocieties.value = [];
            areaSocietiesToEdit.value = [];

            getMarket(item.market.address.location.id);

            item.societies.forEach(ele => {
                areaSocietiesToEdit.value.push(ele.id)
            });

            selectedSocieties.value = areaSocietiesToEdit.value

            geFtUsers(selectedSocieties.value)

            sale.value = {
                id: item.id,
                location_id: item.market.address.location.id,
                season_id: item.season.id,
                buyer_id: item.buyer.id,
                market_id: item.market.id,
                number: item.number,
                saleDate: item.saleDate,
                isCentral: item.isCentral,
                effectiveDate: item.effectiveDate,
                isClosed: !!item.isClosed,
                closeDate: item.closeDate,
                user_id: item.agronomist ? item.agronomist.id : null,
                societies_id: selectedSocieties,
                tmpNumber: item.tmpNumber,
                transporter_id: item.transporter ? item.transporter.id : null,
                societies: item.societies
            }

            myModal.value.show()
        }

        // Form Methods
        const invokeCancelForm = (item) => {
            serverErrors.value = null
            cancelSale.value = {
                id: item.id,
                sale_id: item.id,
                reason: null
            }

            myCancelModal.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            selectedSocieties.value = [];

            sale.value = {
                id: null,
                location_id: sale.value.location_id,
                season_id: sale.value.season_id,
                buyer_id: client.value.type === 0? client.value.id : buyers.value[buyers.value.length - 1].id,
                market_id: sale.value.market_id,
                societies_id: selectedSocieties,
                number: null,
                saleDate: null,
                isCentral: false,
                effectiveDate: null,
                isClosed: false,
                closeDate: null,
                tmpNumber: null,
                user_id: null,
                transporter_id: null,
                societies: []
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (sale.value.id === null || sale.value.id === 0)
                handleCreate()
            else
                handleUpdate(sale.value)
        }

        const submitCancel = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleUpdateCancel(cancelSale.value)
        }

        const handleUpdateCancel = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-sale/cancel', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to sale # ${response.data.number}  has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }



        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()
            // sale.value.market_id = market_id.id
            if (!valid) {
                saving.value = false
                return
            }

            if (sale.value.isCentral) {
                sale.value.location_id = locId.value.id
            }
            // sale.value.market_id = saleData.value.id
            await store.dispatch('cromis-sale/create', sale.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    sale.value = {
                        id: null,
                        location_id: null,
                        season_id: null,
                        buyer_id: null,
                        market_id: null,
                        number: null,
                        saleDate: null,
                        isCentral: false,
                        effectiveDate: null,
                        isClosed: false,
                        closeDate: null,
                        societies_id: null,
                        tmpNumber: null,
                        transporter_id: null,
                        user_id: null,

                    }

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Sale registered successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            if (sale.value.isCentral) {
                sale.value.location_id = locId.value.id
            }
            await store.dispatch('cromis-sale/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to sale # ${response.data.number} of ${response.data.market.code} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-sale/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            client,
            selections,
            formSelections,
            levels,
            children,
            seasons,
            buyers,
            markets,
            sale,
            cancelSale,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            locId,

            // Methods
            getAreaMarket,
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myCancelModal,
            changeFilters,
            changeSeason,
            changeBuyer,
            changeMarket,
            displaySaleNumber,
            changeFormSelection,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            invokeCancelForm,
            submit,
            submitCancel,
            isFormValid,
            handleCreate,
            handleUpdate,
            handleUpdateCancel,
            remove,
            resolveStatusVariant,
            selectedSocieties,
            areaSocieties,
            areaSocietiesToEdit,
            societies,
            onChange,
            minDate,
            transporters,
            saleData,
            fts,
            geFtUsers,
            regions,
            societiesForTmp,
            amcosList
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>